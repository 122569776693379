import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-100"
}
const _hoisted_2 = { class: "required" }
const _hoisted_3 = {
  key: 0,
  class: "invalid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GMapAutocomplete = _resolveComponent("GMapAutocomplete")!

  return (!_ctx.manual)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("label", {
          class: _normalizeClass(["d-flex align-items-center mb-2", _ctx.labelClass])
        }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1)
        ], 2),
        _createVNode(_component_GMapAutocomplete, {
          style: {"z-index":"3000"},
          class: _normalizeClass(["form-control", { 'is-invalid': _ctx.errors.length }]),
          placeholder: _ctx.placeholder,
          onPlace_changed: _ctx.setPlace,
          options: { componentRestrictions: { country: _ctx.country }, types: _ctx.types }
        }, null, 8, ["class", "placeholder", "onPlace_changed", "options"]),
        (_ctx.errors.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.errors[0].$message), 1))
          : _createCommentVNode("", true),
        _createElementVNode("a", {
          href: "javascript:void(0)",
          class: "font-weight-bold d-block mt-2",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.manualInput && _ctx.manualInput(...args)))
        }, "Click here to enter it manually.")
      ]))
    : _createCommentVNode("", true)
}