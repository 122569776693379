
import { defineComponent } from 'vue';
import FormField from '@/components/forms/FormField.vue';
import AddressForm from '@/components/forms/AddressForm.vue';
import { Form } from 'vee-validate';
import { ReferenceRequest } from '@/models/ReferenceRequestModel';
import { ReferenceRequest as ReferenceRequestSchema } from '@/schemas/referenceRequest.schema';
import { mapActions, mapGetters } from 'vuex';
import {
  Actions as RefActions,
  Getters as RefGetters,
} from '@/store/enums/ReferenceRequestEnums';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import toasts from '@/utils/toasts';
import AddressLookup from '@/components/AddressLookup.vue';
import _ from 'lodash';

const initialValues = {
  country: 'AU',
} as unknown as ReferenceRequest;

export default defineComponent({
  name: 'tenant-reference-form',
  emits: ['submitRequestReview'],
  props: {
    customClass: {
      type: String,
      default: 'mx-auto',
    },
  },
  data: () => ({
    loading: false,
    formData: initialValues,
    form: {} as unknown as typeof Form,
    isManual: false,
    formErrors: [] as any
  }),
  mounted() {
    this.form = this.$refs.requesTenantReviewForm as typeof Form;
  },
  components: {
    FormField,
    Form,
    AddressForm,
    AddressLookup
  },
  computed: {
    ...mapGetters({
      requestErrors: RefGetters.GET_ACTION_ERROR,
      authUser: AuthGetters.GET_AUTH_USER,
    }),
    formSchema() {
      return ReferenceRequestSchema;
    },
    initValue() {
      return this.authUser
        ? {
            first_name: this.authUser.first_name,
            last_name: this.authUser.last_name,
            date_of_birth: this.authUser.date_of_birth,
            country: 'AU',
          }
        : initialValues;
    },
    addressErrors(){
      let errors = [] as any;
      const keys = ['address_line', 'city', 'post_code', 'state'];

      _.forEach(this.formErrors, (value, key) => {
        if(keys.includes(key)){
          errors.push({ $message: value });
        }
      });

      return errors;
    }
  },
  methods: {
    ...mapActions({
      requestTenantReference: RefActions.SEND_REQUEST_TENANT_REFERENCE,
    }),
    resetForm() {
      this.form.resetForm();

      // Reset the form values
      this.formData = initialValues;
    },
    initFormValue(values){
      this.formData = {
        ...this.formData,
        ...{
          request_from: 'tenant',
        },
        ...values,
      };
    },
    async submitReferenceRequestForm(values) {

      // console.log('validate',validate);

      if (this.loading) return;
      this.loading = true;

      this.formData = {
        ...this.formData,
        ...{
          request_from: 'tenant',
        },
        ...values,
      };
      const { valid, errors } = await (
        this.$refs.requesTenantReviewForm as any
      ).validate();
      
      if (!valid) {
        this.loading = false;
        this.formErrors = errors;
        return false;
      }

      this.requestTenantReference(this.formData)
        .then(() => {
          this.resetForm();
          toasts.success('Tenant Reference Request Successfully Sent');
          this.formErrors = []
        })
        .catch(() => {
          const { errors, message } = this.requestErrors;
          toasts.error(errors, message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCountryChange(value) {
      this.setFieldValue('country', value);
    },
    handleCountryBlur() {
      this.validateFormField('country');
    },
    handleStateChange(value) {
      this.setFieldValue('state', value);
    },
    handleStateBlur() {
      this.validateFormField('country');
    },
    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
    disableFutureDates(time: Date) {
      return time.getTime() > Date.now();
    },
    setPlace(place){
      this.setFieldValue('state', place.state);
      this.setFieldValue('country', place.country);
      this.setFieldValue('post_code', place.post_code);
      this.setFieldValue('city', place.city);
      this.setFieldValue('address_line', place.address);
    }
  },
  watch: {
    async authUser(value) {
      this.formData = {
        ...this.formData,
        ...{
          first_name: await value.first_name,
          last_name: await value.last_name,
          date_of_birth: await value.date_of_birth,
          country: 'AU',
        },
      };
    },
  },
});
