
import { defineComponent } from 'vue';
import Navigator from '@/views/v2/views/layout/Navigator.vue';
import _ from 'lodash';
import TenantReferenceForm from '@/components/forms/tenant-reference/TenantReferenceForm.vue';

export default defineComponent({
  name: 'tenant-request-reference-page',
  components: { Navigator, TenantReferenceForm },
});
